import { QuickCircuitShipment } from 'src/types/QuickCircuitShipment';
import { QuickCircuitShipmentDevice } from 'src/types/QuickCircuitShipmentDevice';
import { Device } from 'src/types/Device';
import { HotDrop } from 'src/types/HotDrop';
import HotDropStatus from 'src/types/HotDropStatus';
import { User } from 'src/types/User';
import { PropertyInfo } from 'src/types/PropertyInfo';
import { OrganisationFeatureFlag } from 'src/types/OrganisationFeatureFlag';
import { DeviceAuditLog } from 'src/types/DeviceAuditLog';
import { DeviceEventLog } from 'src/types/DeviceEventLog';
import { Installation } from 'src/types/Installation';
import { InstallationLocation } from 'src/types/InstallationLocation';
import { TetherTickLedgerItem } from 'src/types/TetherTickLedgerItem';
import { TetherTickBalance } from 'src/types/TetherTickBalance';
import { LedgerProduct } from 'src/types/LedgerProduct';
import { SystemAccessKey } from 'src/types/SystemAccessKey';
import { SystemAccessRole } from 'src/types/SystemAccessRole';
import { CustomPricingRule } from 'src/types/CustomPricingRule';
import { TetherTickProduct } from 'src/types/TetherTickProduct';
import { InstallationLocationDetail } from 'src/types/InstallationLocationDetail';
import { Report, SystemReportType } from 'src/types/Report';
import moment from 'moment';
import { get, post, put, destroy } from '../utils';
import TetherTickAddCreditResult from '../../../types/TetherTickAddCreditResult';

export function assignShipmentDevicesToCustomer(shipmentId: string, force?: boolean) {
  return post(`quickcircuitshipment/${shipmentId}/assignAllDevices`, { force });
}

export function createDeviceAuditLog(deviceId: string, name: string, value: string): Promise<DeviceAuditLog> {
  const body = {
    deviceId,
    name,
    value,
  };
  return post<DeviceAuditLog>('deviceAuditLog', body);
}

export function createQuickCircuitShipment(orderId: string, details: string): Promise<QuickCircuitShipment> {
  const body = {
    customerOrderId: orderId,
    details,
  };
  return post<QuickCircuitShipment>('quickcircuitshipment', body);
}

export async function createQuickCircuitShipmentDevice(shipmentId: string, deviceSerial: string): Promise<QuickCircuitShipmentDevice> {
  const body = {
    quickCircuitShipmentId: shipmentId,
    deviceSerial,
  };
  return new QuickCircuitShipmentDevice(await post<QuickCircuitShipmentDevice>('quickcircuitshipmentdevice', body));
}

export function createSystemAccessKey(
  username: string,
  secret: string,
  comments?: string,
  permissions?: string[],
  roleIds?: string[]
): Promise<SystemAccessKey> {
  const body = {
    username,
    secret,
    comments,
    permissions,
    roles: roleIds,
  };
  return post<SystemAccessKey>('systemaccesskey', body);
}

export function createSystemAccessPermissionForAccessKey(systemAccessKeyId: string, permission: string): Promise<SystemAccessKey> {
  const body = {
    systemAccessKeyId,
    permission,
  };
  return post<SystemAccessKey>(`systemaccesskey/${systemAccessKeyId}/permission`, body);
}

export function createSystemAccessRoleAssignmentForAccessKey(
  systemAccessKeyId: string,
  systemAccessRoleId: string
): Promise<SystemAccessKey> {
  const body = {
    systemAccessKeyId,
    systemAccessRoleId,
  };
  return post<SystemAccessKey>(`systemaccesskey/${systemAccessKeyId}/role`, body);
}

export function createSystemAccessPermissionForAccessRole(systemAccessRoleId: string, permission: string): Promise<SystemAccessRole> {
  const body = {
    systemAccessRoleId,
    permission,
  };
  return post<SystemAccessRole>(`systemaccessrole/${systemAccessRoleId}/permission`, body);
}

export function createSystemAccessRole(name: string, description?: string, permissions?: string[]): Promise<SystemAccessRole> {
  const body = {
    name,
    description,
    permissions,
  };
  return post<SystemAccessRole>('systemaccessrole', body);
}

export function createSystemReport(type: SystemReportType): Promise<Report> {
  const body = {
    type,
    entityName: 'SYSTEM',
    entityId: 'SYSTEM',
    parameters: `{startTime: ${moment().valueOf()}}`,
  };
  return post<Report>('report', body);
}

export function createUser(email: string, firstName: string, lastName: string, password: string): Promise<User> {
  const body = {
    email,
    firstName,
    lastName,
    password,
  };
  return post<User>('user', body);
}

export async function deleteCustomPricingRule(id: string): Promise<CustomPricingRule> {
  return destroy(`custompricingrule/${id}`);
}

export function deleteQuickCircuitShipmentDevice(shipmentId: string, deviceId: string): Promise<boolean> {
  const params = {
    quickCircuitShipmentId: shipmentId,
    deviceId,
  };

  return destroy('quickcircuitshipmentdevice', params);
}

export function deleteQuickCircuitShipment(shipmentId: string): Promise<boolean> {
  return destroy(`quickcircuitshipment/${shipmentId}`);
}

export function deleteSystemAccessPermissionForAccessKey(systemAccessKeyId: string, permission: string): Promise<boolean> {
  return destroy(`systemaccesskey/${systemAccessKeyId}/permission/${permission}`);
}

export function deleteSystemAccessRoleAssignmentForAccessKey(systemAccessKeyId: string, systemAccessRoleId: string): Promise<boolean> {
  return destroy(`systemaccesskey/${systemAccessKeyId}/role/${systemAccessRoleId}`);
}

export function deleteSystemAccessPermissionForAccessRole(systemAccessRoleId: string, permission: string): Promise<boolean> {
  return destroy(`systemaccessrole/${systemAccessRoleId}/permission/${permission}`);
}

export function downloadReport(reportId: string): Promise<any> {
  return get<any>(`report/${reportId}/download`);
}

export function getAllFeatureFlagNames(): Promise<string[]> {
  return get<string[]>('organisationFeatureFlag/names');
}

export function getAllSystemAccessKeyPermissions(): Promise<string[]> {
  return get<string[]>('systemaccesskey/permissions');
}

export function getAllSystemAccessRolePermissions(): Promise<string[]> {
  return get<string[]>('systemaccessrole/permissions');
}

export function getReport(reportId: string): Promise<Report> {
  return get<Report>(`report/${reportId}`);
}

export function getSystemAccessKey(accessKeyId: string): Promise<SystemAccessKey> {
  return get<SystemAccessKey>(`systemaccesskey/${accessKeyId}`);
}

export function getSystemAccessKeys(): Promise<SystemAccessKey[]> {
  return get<SystemAccessKey[]>('systemaccesskey');
}

export function getSystemAccessRole(accessRoleId: string): Promise<SystemAccessRole> {
  return get<SystemAccessRole>(`systemaccessrole/${accessRoleId}`);
}

export function getSystemAccessRoles(): Promise<SystemAccessRole[]> {
  return get<SystemAccessRole[]>('systemaccessrole');
}

export function getSystemReports(): Promise<Report[]> {
  return get<Report[]>('report', { entityId: 'SYSTEM' });
}

export function getCustomPricingRulesForOrganisation(organisationId: string): Promise<CustomPricingRule[]> {
  return get<CustomPricingRule[]>('custompricingrule', { organisationId });
}

export function getDeviceAuditLogs(deviceId: string): Promise<DeviceAuditLog[]> {
  return get<DeviceAuditLog[]>('deviceAuditLog', { deviceId });
}
export function getDeviceEventLogs(deviceId: string): Promise<DeviceEventLog[]> {
  return get<DeviceEventLog[]>(`event/?objectId=${deviceId}`);
}

export function getHotDropStatus(deviceId: string): Promise<HotDropStatus> {
  return get<HotDropStatus>(`hotdrop/${deviceId}/status`);
}

export function getFeatureFlagsForOrganisation(organisationId: string): Promise<OrganisationFeatureFlag[]> {
  return get<OrganisationFeatureFlag[]>('organisationfeatureflag', {
    organisationId,
  });
}

export async function getHotdrop(hotdropId, options?: { retry: false }): Promise<HotDrop> {
  return new HotDrop(await get<HotDrop>(`hotdrop/${hotdropId}`, undefined, options?.retry === false ? 3 : 0));
}

export async function unprovisionHotdrop(hotdropId: string) {
  await post(`hotdrop/${hotdropId}/unprovision`);
}

export async function getHotdrops(): Promise<HotDrop[]> {
  return (await get<HotDrop[]>('hotdrop')).map((result) => new HotDrop(result));
}

export function getInstallationById(id): Promise<Installation> {
  return get<Installation>(`installation/${id}`);
}

export function getInstallationsByOrganisationId(organisationId): Promise<Installation[]> {
  return get<Installation[]>('installation', { organisationId });
}

export function getInstallationLocationById(id): Promise<InstallationLocation> {
  return get<InstallationLocation>(`installationlocation/${id}`);
}

export function getInstallationLocationsByInstallationId(installationId): Promise<InstallationLocation[]> {
  return get<InstallationLocation[]>('installationlocation', {
    installationId,
  });
}

export function getPropertyInfo(installationId): Promise<PropertyInfo> {
  return get<PropertyInfo>(`experience/property-info/${installationId}`, {useV2: "true"});
}

export function getInstallationLocationsDetail(installationLocationId, name): Promise<InstallationLocationDetail> {
  return get<InstallationLocationDetail>(`installationlocation/${installationLocationId}/detail/${name}`);
}

export function getLedgerProductsForCustomer(customerId: string): Promise<LedgerProduct[]> {
  return get<LedgerProduct[]>('ledgerproduct', { customerId });
}

export function getTetherTickBalanceForCustomer(customerId: string): Promise<TetherTickBalance> {
  return get<TetherTickBalance>(`tethertickcredits/${customerId}`);
}

export function addTetherTickCreditToCustomer(customerId: string, amount: number): Promise<TetherTickAddCreditResult> {
  return post<TetherTickAddCreditResult>(`tethertickcredits`, {
    customerId,
    amount
  });
}

export function getTetherTickCreationLedger(): Promise<TetherTickLedgerItem[]> {
  return get<TetherTickLedgerItem[]>('ledger', {
    name: 'TETHER_TICK_CERTIFICATE_CREATED',
  });
}

export function getTetherTickLedgerForInstallation(installationId: string): Promise<TetherTickLedgerItem[]> {
  return get<TetherTickLedgerItem[]>('ledger', {
    targetId: installationId,
    name: 'TETHER_TICK_CERTIFICATE_CREATED',
  });
}

export function getTetherTickLedgerForOrganisation(organisationId: string): Promise<TetherTickLedgerItem[]> {
  return get<TetherTickLedgerItem[]>('ledger', { organisationId });
}

export function getTetherTickProductsForOrganisation(organisationId: string): Promise<TetherTickProduct[]> {
  return get<TetherTickProduct[]>('stripe/tethertickproducts', {
    organisationId,
  });
}

export function getPermissions(): Promise<string[]> {
  return get<string[]>('permissions');
}

export function getQuickCircuitShipmentById(shipmentOrderId: string): Promise<QuickCircuitShipment> {
  return get<QuickCircuitShipment>(`quickcircuitshipment/${shipmentOrderId}`);
}

export function getQuickCircuitShipmentsForOrder(orderId: string): Promise<QuickCircuitShipment[]> {
  return get<QuickCircuitShipment[]>('quickcircuitshipment', {
    customerOrderId: orderId,
  });
}

export async function getQuickCircuitShipmentDevices(shipmentId: string): Promise<QuickCircuitShipmentDevice[]> {
  return (
    await get<QuickCircuitShipmentDevice[]>('quickcircuitshipmentdevice', {
      quickCircuitShipmentId: shipmentId,
    })
  ).map((device) => new QuickCircuitShipmentDevice(device));
}

export async function getQuickCircuitShipmentDevicesSince(startDate: string): Promise<QuickCircuitShipmentDevice[]> {
  return (
    await get<QuickCircuitShipmentDevice[]>('quickcircuitshipmentdevice', {
      createdAtStartDate: startDate,
    })
  ).map((device) => new QuickCircuitShipmentDevice(device));
}

export function getUserById(userId: string): Promise<User> {
  return get<User>(`User/${userId}`);
}

/**
 * @deprecated Use searchDevicesV2 instead
 */
export async function searchDevices(serialNumber?: string, sigfoxId?: string, devEUI?: string): Promise<Device[]> {
  return (await get<Device[]>('device', { serialNumber, sigfoxId, devEUI })).map((d) => new Device(d));
}

export type SearchDeviceQuery = {
  serialNumber?: string;
  sigfoxId?: string;
  devEUI?: string;
  shortSerial?: string;
};

export async function searchDevicesV2(query: SearchDeviceQuery): Promise<Device[]> {
  return (await get<Device[]>('device', query)).map((d) => new Device(d));
}

export async function searchHotdrops(serialNumber?: string, vutilitiId?: string): Promise<HotDrop[]> {
  return (await get<HotDrop[]>('hotdrop', { serialNumber, vutilitiId })).map((hd) => new HotDrop(hd));
}

export function searchUsers(email: string): Promise<User[]> {
  return get<User[]>('user', { email });
}

export function sendTrackingLinkEmailToCustomer(shipmentId: string, email: string): Promise<void> {
  return post<void>(`quickcircuitshipment/${shipmentId}/trackingEmail`, {
    email,
  });
}

export function setFeatureFlagForOrganisation(organisationId: string, name: string, value: boolean): Promise<OrganisationFeatureFlag> {
  const body = {
    organisationId,
    name,
    value,
  };

  return post<OrganisationFeatureFlag>('organisationfeatureflag', body);
}

export async function createCustomPricingRule(organisationId: string, productCode: string, price: number): Promise<CustomPricingRule> {
  const body = {
    organisationId,
    productCode,
    price,
  };

  return post('custompricingrule', body);
}

export function updateQuickCircuitShipment(shipment: QuickCircuitShipment) {
  return put<QuickCircuitShipment>(`quickcircuitshipment/${shipment.id}`, {
    ...shipment,
  });
}

export function updateSystemAccessKeyPassword(systemAccessKeyId: string, password: string) {
  const body = {
    systemAccessKeyId,
    secret: password,
  };
  return put<SystemAccessKey>(`systemaccesskey/${systemAccessKeyId}/secret`, body);
}
