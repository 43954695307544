import { useQuery, UseQueryResult } from 'react-query';
import { CustomerInvoice } from 'src/types/CustomerInvoice';
import { CustomerMonthlyBill } from 'src/types/CustomerMonthlyBill';
import { DeviceContract } from 'src/types/DeviceContract';
import { get, post, put, destroy } from '../utils';

export function createDeviceContract(contract: Partial<DeviceContract>): Promise<DeviceContract> {
  return post<DeviceContract>('devicecontract', contract);
}

export function getDeviceContract(deviceContractId: string): Promise<DeviceContract> {
  return get<DeviceContract>(`devicecontract/${deviceContractId}`);
}

export function generateBillingData(deviceContractId: string, timestamp: string): Promise<CustomerMonthlyBill> {
  return post<CustomerMonthlyBill>(`devicecontract/${deviceContractId}/generateBillingData`, { timestamp });
}

export function generateAllBillingData(timestamp: string): Promise<CustomerMonthlyBill[]> {
  return post<CustomerMonthlyBill[]>('devicecontract/generateAllBillingData', {
    timestamp,
  });
}

export function useQueryAllBillingData(timestamp: string): UseQueryResult<CustomerMonthlyBill[], Error> {
  return useQuery(['AllBillingData', timestamp], () => generateAllBillingData(timestamp));
}

export function generateInvoiceForMonth(deviceContractIds: string[], timestamp: string): Promise<CustomerInvoice> {
  return post<CustomerInvoice>(`devicecontract/generateInvoiceForMonth`, {
    deviceContractIds,
    timestamp,
  });
}

export function generateAllInvoicesForMonth(timestamp: string): Promise<CustomerInvoice[]> {
  return post<CustomerInvoice[]>('devicecontract/generateAllInvoicesForMonth', {
    timestamp,
  });
}

export function deleteDeviceContract(deviceContractId: string): Promise<void> {
  return destroy(`devicecontract/${deviceContractId}`);
}

export interface DeviceContractSearchParams {
  customerId?: string;
  createdFromCustomerOrderId?: string;
  includeDeleted?: boolean;
}

export function searchDeviceContracts(params: DeviceContractSearchParams): Promise<DeviceContract[]> {
  return get<DeviceContract[]>('devicecontract', { ...params });
}


export function updateDeviceContract(deviceContractId: string, updatedFields: Partial<DeviceContract>): Promise<DeviceContract> {
  if (updatedFields.id && updatedFields.id !== deviceContractId) {
    throw new Error('Contract IDs must match');
  }

  const body = {
    ...updatedFields,
    id: deviceContractId,
  };

  return put<DeviceContract>(`devicecontract/${deviceContractId}`, body);
}
