import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAggregatedQueries } from 'src/hooks/useAggregatedQuery';
import { DeviceAssignment } from 'src/types/DeviceAssignment';
import { destroy, get } from '../utils';

type DeviceAssignmentSearchParams = {
  deviceId?: string;
  organisationId?: string;
  installationId?: string;
  installationLocationId?: string;
  createdAtStartDate?: string;
  includeDeleted?: boolean;
};

export function searchDeviceAssignments(params: DeviceAssignmentSearchParams): Promise<DeviceAssignment[]> {
  return get<DeviceAssignment[]>('deviceassignment', { ...params });
}

export function getDeviceAssignmentsForDevice(deviceId: string, includeDeleted?: boolean): Promise<DeviceAssignment[]> {
  return get<DeviceAssignment[]>('deviceassignment', {
    deviceId,
    includeDeleted: includeDeleted ? 'true' : 'false',
  });
}

export function getDeviceAssignmentsForInstallation(installationId: string, includeDeleted: boolean = false): Promise<DeviceAssignment[]> {
  const query: Record<string, string> = { installationId };
  if (includeDeleted) {
    query.includeDeleted = 'true';
  }
  return get<DeviceAssignment[]>('deviceassignment', query);
}

export function useGetDeviceAssignmentsForProperty(propertyId: string, includeDeleted: boolean = false): UseQueryResult<DeviceAssignment[]> {
  return useQuery(['DeviceAssignments', propertyId, includeDeleted], () => getDeviceAssignmentsForInstallation(propertyId, includeDeleted));
}

export function useGetDeviceAssignmentsForMultipleProperties(propertyIds: string[], includeDeleted: boolean = false, options?: UseQueryOptions<DeviceAssignment[], Error>) {
  return useAggregatedQueries<DeviceAssignment[], Error>(
    propertyIds.map((propertyId) => ({
      queryKey: ['DeviceAssignments', propertyId, includeDeleted],
      queryFn: () => getDeviceAssignmentsForInstallation(propertyId, includeDeleted),
      ...options,
    }))
  );
}

export function getDeviceAssignmentsForInstallationLocation(installationLocationId: string): Promise<DeviceAssignment[]> {
  return get<DeviceAssignment[]>('deviceassignment', {
    installationLocationId,
  });
}

export function getDeviceAssignmentsSince(timestamp: string): Promise<DeviceAssignment[]> {
  return get<DeviceAssignment[]>('deviceassignment', {
    createdAtStartDate: timestamp,
  });
}

export function useQueryAllDeviceAssignmentsForDeviceIds(
  ids: string[],
  includeDeleted?: boolean,
  enabled?: boolean
) {
  return useAggregatedQueries<DeviceAssignment[]>(
    enabled !== undefined && !enabled
      ? []
      : ids.map((id) => {
          const deviceId = id;
          return {
            queryKey: ['DeviceAssignmentForDevice', deviceId],
            queryFn: async () => {
              const device = await getDeviceAssignmentsForDevice(deviceId, includeDeleted);
              return device;
            },
          };
        })
  );
}

export function useQueryDeviceAssignments(params: DeviceAssignmentSearchParams): UseQueryResult<DeviceAssignment[]> {
  return useQuery(['DeviceAssignments', ...Object.values(params)], () => searchDeviceAssignments(params));
}

export function useQueryAllDeviceAssignmentsForOrganisationIds(
  ids: string[],
  includeDeleted?: boolean,
  enabled?: boolean
) {
  return useAggregatedQueries<DeviceAssignment[]>(
    enabled !== undefined && !enabled
      ? []
      : ids.map((id) => {
          const organisationId = id;
          return {
            queryKey: ['DeviceAssignment', organisationId],
            queryFn: async () => {
              const device = await searchDeviceAssignments({
                organisationId,
                includeDeleted,
              });
              return device;
            },
          };
        })
  );
}

export async function deleteDeviceAssignment(deviceAssignment: DeviceAssignment) {
  await destroy(`/deviceassignment/${deviceAssignment.id}`);
}
