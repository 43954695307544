import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import axios from '../lib/axios';
import TetherApi from '../lib/tether-microservices/TetherApi';
import { setAccessToken } from '../lib/tether-microservices/utils';

const API_HOST = process.env.REACT_APP_API_HOST;
const COOKIE_NAME = 'saved_ms_token';
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  permissions: string[];
}

interface AuthContextValue extends State {
  platform: 'Cookie';
  login: (token: string, secret: string) => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    accessToken: string;
    permissions: string[];
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    accessToken: string;
    permissions: string[];
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  accessToken: null,
  permissions: [],
};

const getTokenFromLocalStorage = (name) : string => {
  const value = `;${localStorage.getItem('auth_cookie')}`;
  const parts = value.split(`${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return null;
};

const writeTokenToLocalStorage = (accessToken: string, expires: number) => {
  let cookie = '';
  if (accessToken) {
    let expiresString = '';
    if (expires) {
      const date = new Date();
      date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
      expiresString = `; expires=${date.toUTCString()}`;
    }
    cookie = `${COOKIE_NAME}=${accessToken}${expiresString};${COOKIE_DOMAIN ? `domain=${COOKIE_DOMAIN};` : ''}path=/ `;
  } else {
    cookie = `${COOKIE_NAME}=;${COOKIE_DOMAIN ? `domain=${COOKIE_DOMAIN};` : ''}path=/`;
  }

  localStorage.setItem('auth_cookie', cookie);
};

// TODO: reinstate as cookie once permissions used as role-based
// const writeTokenToCookie = (accessToken: string, expires: number) => {
//   if (accessToken) {
//     let expiresString = '';
//     if (expires) {
//       const date = new Date();
//       date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
//       expiresString = `; expires=${date.toUTCString()}`;
//     }
//     document.cookie = `${COOKIE_NAME}=${accessToken}${expiresString};${COOKIE_DOMAIN ? `domain=${COOKIE_DOMAIN};` : ''}path=/ `;
//   } else {
//     document.cookie = `${COOKIE_NAME}=;${COOKIE_DOMAIN ? `domain=${COOKIE_DOMAIN};` : ''}path=/`;
//   }
// };

// const getTokenFromCookie = (name) : string => {
//   const value = `;${document.cookie}`;
//   const parts = value.split(`${name}=`);
//   if (parts.length === 2) {
//     return parts.pop().split(';').shift();
//   }

//   return null;
// };

const setSession = (accessToken: string | null, expires: number = 1): void => {
  writeTokenToLocalStorage(accessToken, expires);
  if (accessToken) {
    setAccessToken(accessToken);
  } else {
    setAccessToken(null);
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      permissions
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { permissions } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      permissions
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    accessToken: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Cookie',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = getTokenFromLocalStorage(COOKIE_NAME);

        if (accessToken) {
          setSession(accessToken);

          const permissions = await TetherApi.getPermissions();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              accessToken,
              permissions
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              accessToken: null,
              permissions: []
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            accessToken: null,
            permissions: []
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (key: string, secret: string): Promise<void> => {
    if (!API_HOST) {
      throw new Error('API_HOST is not set');
    }

    const response = await axios.post<{ result: string; value: any }>(`${API_HOST}/auth/login`, {
      access_key: key,
      access_secret: secret
    });

    const { value } = response.data;
    setSession(value.token);
    const permissions = await TetherApi.getPermissions();

    dispatch({
      type: 'LOGIN',
      payload: {
        accessToken: value.token,
        permissions
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Cookie',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
