import { useContext } from 'react';
import { Box, TableCell, TableRow, TextField, Typography, Switch, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import TetherDatePicker from 'src/components/TetherDatePicker';
import useLog from 'src/hooks/useLog';
import { camelCaseToUserText } from 'src/utils/stringUtils';
import { format } from 'date-fns';
import { CurrencyCode, CurrencyDescription } from 'src/types/CurrencyCode';
import { EditablePropertyListContext } from './EditablePropertyListContext';
import CopyToClipboardButton from '../CopyToClipboardButton';
import CurrencySelector from '../currency/CurrencySelector';
import PriceSetDisplay from '../currency/PriceSetDisplay';
import PriceSetSelector from '../currency/PriceSetSelector';

export enum EditablePropertyType {
  TEXT = 'text',
  NUMBER = 'number',
  FLOAT = 'float',
  RADIO = 'radio',
  SWITCH = 'switch',
  DATE = 'date',
  DATETIME = 'datetime',
  CURRENCY = 'currency',
  PRICE_SET = 'priceSet',
}
export interface EditablePropertyRadioOption {
  label?: string;
  value: string;
}
interface PropertyProps<T> {
  editable?: boolean;
  editableType?: EditablePropertyType;
  editableRadioOptions?: EditablePropertyRadioOption[];
  fieldName: string;
  multiline?: boolean;
  title?: string;
  getTitle?: (value: string, displayObject: T) => string;
  customRender?: (value: string, displayObject: T) => string | JSX.Element;
  customEditRender?: (value: string, displayObject: T, renderDefault: () => JSX.Element) => JSX.Element;
  copyToClipboard?: boolean;
}

function EditableProperty<T>(props: PropertyProps<T>): JSX.Element {
  const { properties, editing, editedProperties, updateProperty } = useContext(EditablePropertyListContext);
  const { title, fieldName, editable, editableType, editableRadioOptions, multiline, getTitle, customRender, copyToClipboard } = props;
  const log = useLog();

  const renderEditValue = (value: unknown) => {
    switch (editableType) {
      case EditablePropertyType.SWITCH:
        return <Switch checked={value as boolean} color="primary" onChange={(event) => updateProperty(fieldName, event.target.checked)} />;
      case EditablePropertyType.RADIO:
        return (
          <RadioGroup
            name="fieldName"
            value={value}
            onChange={(_, selected) => updateProperty(fieldName, selected)}
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {editableRadioOptions.map((o) => {
              const option = o;
              return (
                <FormControlLabel
                  sx={{ flex: 1, whiteSpace: 'nowrap' }}
                  id={`PropertyOption ${option.value}`}
                  label={option.label || option.value}
                  value={option.value}
                  control={<Radio />}
                />
              );
            })}
          </RadioGroup>
        );
      case EditablePropertyType.CURRENCY:
        return <CurrencySelector selectedCurrency={value as CurrencyCode} onChange={(currency) => updateProperty(fieldName, currency)} />;
      case EditablePropertyType.PRICE_SET:
        return <PriceSetSelector selectedPriceSetId={value as string} onChange={(priceSetId) => updateProperty(fieldName, priceSetId)} />;
      case EditablePropertyType.DATE:
        return (
          <TetherDatePicker
            value={value}
            onChange={(newValue) => updateProperty(fieldName, newValue as string)}
            size='small'
            sx={{
              ml: -2,
              mr: -2,
              mt: -1,
              mb: -1,
              width: '100%',
            }}
          />
        );
      default:
        return (
          <TextField
            value={value}
            onChange={(event) => {
              let newValue: string | number = event.target.value;
              if (editableType === EditablePropertyType.NUMBER) newValue = parseInt(newValue, 10);
              if (editableType === EditablePropertyType.FLOAT) newValue = parseFloat(newValue as string);
              updateProperty(fieldName, newValue);
            }}
            type={[EditablePropertyType.NUMBER, EditablePropertyType.FLOAT].includes(editableType) ? 'number' : 'text'}
            size="small"
            sx={{
              ml: -2,
              mr: -2,
              mt: -1,
              mb: -1,
              width: '100%',
              textarea: {
                pt: 0,
              },
            }}
            multiline={multiline}
          />
        );
    }
  };

  const renderValue = () => {
    if (!properties) {
      return null;
    }

    if (editable && editing) {
      const value = Object.keys(editedProperties).includes(fieldName) ? editedProperties[fieldName] : properties[fieldName];

      /** Edit Mode */
      const renderDefault = () => renderEditValue(value);
      if (props.customEditRender) {
        return props.customEditRender(value, properties as T, renderDefault);
      } else {
        return renderDefault();
      }
    }

    /** Display Mode */
    const renderInner = () => {
      if (customRender) {
        return customRender(properties[fieldName], properties as any);
      }

      switch (editableType) {
        case EditablePropertyType.DATE:
          return properties[fieldName] ? format(new Date(properties[fieldName]), 'dd/MM/yyyy') : '';
        case EditablePropertyType.DATETIME:
          return properties[fieldName] ? format(new Date(properties[fieldName]), 'dd/MM/yyyy - HH:mm') : '';
        case EditablePropertyType.CURRENCY:
          return CurrencyDescription[properties[fieldName]];
        case EditablePropertyType.PRICE_SET:
          return <PriceSetDisplay priceSetId={properties[fieldName]} />;
        default:
          return properties[fieldName];
      }
    };

    return (
      <Typography color="textSecondary" variant="body2" component="span" sx={{ whiteSpace: 'break-spaces' }}>
        {renderInner()}
      </Typography>
    );
  };

  /** Row Render */
  return (
    <TableRow>
      <TableCell>
        <Typography color="textPrimary" variant="subtitle2">
          {getTitle ? properties && getTitle(properties[fieldName], properties as any) : title || camelCaseToUserText(fieldName)}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {renderValue()}
          {!editing && !!properties && !!properties[fieldName] && copyToClipboard && (
            <CopyToClipboardButton
              sx={{ mt: -2, mb: -2 }}
              text={
                customRender && typeof customRender(properties[fieldName], properties as any) === 'string'
                  ? customRender(properties[fieldName], properties as any)
                  : properties[fieldName].toString()
              }
              log={log}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default EditableProperty;
